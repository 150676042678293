function init() {
  $('.material-deliveries-table').dataTable({
    "destroy": true,
    "paging": false,
    "filter": false,
    "ordering": true,
    "orderMulti": true,
    "order": [[0, "desc"], [1, "desc"]],
    "dom": 't',
    "language": {
      "decimal":        "",
      "emptyTable":     "No data available in table",
      "info":           "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
      "infoEmpty":      "Keine Ergebnisse",
      "infoFiltered":   "(aus _MAX_ Einträgen)",
      "infoPostFix":    "",
      "thousands":      ",",
      "lengthMenu":     "Show _MENU_ entries",
      "loadingRecords": "Lade...",
      "processing":     "Verarbeite...",
      "search":         "Filter:",
      "zeroRecords":    "Keine passenden Einträge gefunden",
      "paginate": {
        "first":      "First",
        "last":       "Last",
        "next":       "Next",
        "previous":   "Previous"
      },
      "aria": {
        "sortAscending":  ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
      }
    }
  });
}

module.exports = { init }

/*
 * possibly refreshes any fullcalendar found on page (for reservations and tasks)
 */
function refresh_fullcalendar() {
  $('fullcalendar-webcomponent').each(function() { this.refresh() })
}

/*
 * redraw any fullcalendar (e.g. if it was created before page was fully loaded)
 */
function redraw_fullcalendar() {
  $('fullcalendar-webcomponent').each(function() { this.redraw() })
}

function gotoDate(iso_date) {
  $('fullcalendar-webcomponent').each(function() { this.calendar.gotoDate(iso_date) })
}

function event_change(calendar_component, ev, type, revertFunc) {
  var startStr = calendar_component.getDateString(ev.start);
  var endStr = calendar_component.getDateString(ev.end);
  var data = {};
  data[type] = { start_at: startStr, stop_at: endStr }
  $.ajax({
    url: ev.url + '.json',
    data: data,
    method: "PUT",
    context: document.body
  }).done(function(data, status, xhr) {
    if (data.replace) {
      for (var id in data.replace) {
        $(id).replaceWith(data.replace[id]);
        common.init_js_controls($(id));
      }
    }
    calendar_component.refresh();
    calendar_component.fireDataChanged();
  }).fail(function(xhr, status, error) {
    var msg = "Fehler beim Speichern /";
    try {
      errors = $.parseJSON(xhr.responseText);
      $.each(errors, function(field, messages) {
        msg = msg + "/ " + field + ": " + messages.join(', ');
      });
    } catch (exc) {
      msg += " (Konnte Fehlermeldung nicht lesen) ";
      console.error("Fehler beim Lesen der Antwort: " + exc + "\nInhalt der Antwort: \n" + xhr.responseText);
    }
    msg += " / Kalender wird neu geladen...";
    // $(this).render_form_errors($.parseJSON(xhr.responseText));
    alert(msg);
    calendar_component.refresh();
  });
}

function addPopover(info) {
  let ev = info.event;
  if (!ev.extendedProps.popover_content) return;

  let delay = 0;
  let popoverClass = "";
  if (Array.from(info.el.classList).includes("type-run-overview")) {
    delay = 500;
    popoverClass = "popover-run-overview";
  }

  let delay_option = { show: (delay || 0), hide: 0 };
  if (ev.extendedProps.popover_title === undefined
      || ev.extendedProps.popover_content === undefined) {
    // don't add popover if title or content is missing
    return;
  }
  var el = $(info.el);
  var placeLeft = info.view.currentStart < ev.start && (ev.start.getDay() > 4 || ev.start.getDay() == 0);

  el.popover({
    content: ev.extendedProps.popover_content,
    title: ev.extendedProps.popover_title,
    html: true,
    // our custom whitelist
    whiteList: popoverWhiteList,
    delay: delay_option,
    trigger: "hover",
    container: "body",
    placement: ev.allDay ? "bottom" : (placeLeft ? 'left' : 'right'),
    boundary: "window",
    // https://getbootstrap.com/docs/4.5/components/popovers/#usage
    // add custom class to popover
    // FIXME: change on upgrade to newer bootstrap
    template: '<div class="popover no-pointer-events ' + popoverClass + '" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  });
}

function fcDefaultConfig(initial_date, datesRenderCallback) {
  if (initial_date === undefined)
    initial_date = (new Date()).toISOString();

  return {
    loading: function(isLoading) {
      // FIXME: add a spinner
      if (!isLoading && datesRenderCallback)
        datesRenderCallback();
    },
    eventClick: function(info) {
      info.jsEvent.preventDefault();
      var url = info.event.url;
      if (url)
        common.openModal(url);
    },
    initialDate: initial_date,  // pass default date in case local date is wrong
    timeZone: 'Europe/Berlin', // always use German timezone to avoid time shifting
                               // if ibook is used in other countries
    height: "calc(100vh - 245px)",
    locales: [ calendar_de ],
    locale: moment.locale(),
    firstDay: 1,
    eventDisplay: "block",
    allDayContent: '',
    weekNumbers: true,
    scrollTime: '7:00',
    longPressDelay: 200,
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'dayGridMonth,timeGridWeek,timeGridWorkWeek,timeGridDay prev,next today'
    },
    selectMirror: true,
    selectMinDistance: 2,
    slotDuration: '00:30:00',
    snapDuration: '00:30:00',
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
    views: {
      timeGridWorkWeek: {
        type: 'timeGrid',
        hiddenDays: [6,0],
        duration: { weeks: 1 },
        buttonText: i18n.t("fullcalendar.work_week"),
      },
      timeGridDay: {
        type: 'timeGrid',
        duration: { days: 3 },
        buttonText: i18n.t("fullcalendar.three_days"),
      },
    },
    plugins: [ RunOverviewViewPlugin, dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ],
    themeSystem: 'bootstrap',
    initialView: 'timeGridWeek',
    editable: true, // FIXME: shouldn't the default be false?
    droppable: true,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    eventOrder: "position,end,start,-duration,allDay,title",
    eventOverlap: true,
    businessHours: {
      // days of week. an array of zero-based day of week integers (0=Sunday)
      daysOfWeek: [1, 2, 3, 4, 5],

      startTime: '08:00', // a start time (10am in this example)
      endTime: '18:00', // an end time (6pm in this example)
    },
  }
};

////////////////////////////////////////////////////////////////////////////////
//                        Cluster reservations
////////////////////////////////////////////////////////////////////////////////

function fcClusterReservationConfig(calendar_component, initial_date, cluster_id) {
  config = {
    eventSources: [
      holidays_feed(calendar_component),
      {
        url: Routes.cluster_reservations_path(cluster_id, { format: "json" }),
        id: "cluster-reservations-feed",
        className: "type-run",
        editable: false,
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "cluster-reservations-feed"),
      },
      {
        url: Routes.cluster_runs_overviews_path(cluster_id, { format: "json" }),
        id: "runs-overviews-feed",
        className: "type-run-overview",
        // defaultAllDay: true,
        editable: false,
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "runs-overviews-feed"),
      },
      {
        url: Routes.cluster_utilization_index_path(cluster_id, { format: "json" }),
        id: "utilization-feed",
        className: "type-utilization",
        textColor: "black",
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "utilization-feed"),
      }
    ],
    height: "calc(100vh - 145px)",
    headerToolbar: {
      left: 'title',
      center: 'custom1Week custom4Weeks',
      right: 'timeGridWeek,timeGridWorkWeek,timeGridDay prev,next today'
    },
    editable: false,
    selectable: false,
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
    },
    eventClick: function(info) {
      info.jsEvent.preventDefault();
      var url = info.event.url;
      if (url)
        if (info.event.extendedProps.new_window) {
          window.open(url, "_blank");
        } else {
          common.openModal(url);
        }
    },
  }
  return $.extend(fcDefaultConfig(initial_date, undefined), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        Machine reservations
////////////////////////////////////////////////////////////////////////////////

function fcMachineReservationConfig(calendar_component, initial_date, datesRenderCallback, machine_id, allowNew) {
  config = {
    eventSources: [
      holidays_feed(calendar_component),
      {
        url: Routes.machine_reservations_path(machine_id, { format: "json" }),
        id: "machine-reservations-feed",
        className: "type-run",
        editable: true,
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "machine-reservations-feed"),
      },
      {
        url: Routes.machine_runs_overviews_path(machine_id, { format: "json" }),
        id: "runs-overviews-feed",
        className: "type-run-overview",
        // defaultAllDay: true,
        editable: false,
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "runs-overviews-feed"),
      },
      {
        url: Routes.machine_utilization_index_path(machine_id, { format: "json" }),
        id: "utilization-feed",
        className: "type-utilization",
        textColor: "black",
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "utilization-feed"),
      }
    ],
    headerToolbar: {
      left: 'title',
      center: 'custom1Week custom4Weeks',
      right: 'dayGridMonth,timeGridWeek,timeGridWorkWeek,timeGridDay prev,next today'
    },
    height: "calc(100vh - 300px)",
    editable: true,
    selectable: true,
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
    },
    eventDrop: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventResize: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    select: function(selectInfo) {
      common.openModal(Routes.new_reservation_path(
        {
          'reservation[start_at]': selectInfo.startStr,
          'reservation[stop_at]': selectInfo.endStr,
          'reservation[machine_id]': machine_id
        }
      ))
    },
    selectAllow: function(event) {
      return allowNew;
    }
  }
  return $.extend(fcDefaultConfig(initial_date, datesRenderCallback), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        RUN reservations
////////////////////////////////////////////////////////////////////////////////

function fcRunReservationConfig(calendar_component, initial_date, run_id) {
  config = {
    eventSources: [
      holidays_feed(calendar_component),
      {
        id: "run",
        url: Routes.run_reservations_path(run_id, { format: "json" }),
        className: "type-run",
        editable: true
      }
    ],
    height: "100%",
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
      calendar_component.possiblyHighlightEvent(info);
    },
    eventDragStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventDrop: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventResizeStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventResize: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventReceive: function(info) {
      var start_at = calendar_component.getDateString(info.event.start);
      var stop_at = calendar_component.getDateString(info.event.end);
      common.openModal(Routes.new_reservation_path(
        {
          'reservation[start_at]':    start_at,
          'reservation[stop_at]':     stop_at,
          'reservation[run_id]':      info.event.extendedProps.run_id,
          'reservation[run_item_id]': info.event.extendedProps.run_item_id,
          'reservation[machine_id]':  info.event.extendedProps.machine_id
        }
      ));
      info.event.remove(); // remove event in case new reservation dialog is cancelled
    }
  }
  return $.extend(fcDefaultConfig(initial_date, undefined), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        Queue reservations
////////////////////////////////////////////////////////////////////////////////

function fcQueueReservationConfig(calendar_component, initial_date) {
  config = {
    eventSources: [holidays_feed(calendar_component)],
    height: "100%",
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
      calendar_component.possiblyHighlightEvent(info);
    },
    eventDragStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventDrop: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventResizeStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventResize: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventReceive: function(info) {
      var start_at = calendar_component.getDateString(info.event.start);
      var stop_at = calendar_component.getDateString(info.event.end);
      common.openModal(Routes.new_reservation_path(
        {
          'reservation[start_at]':    start_at,
          'reservation[stop_at]':     stop_at,
          'reservation[run_id]':      info.event.extendedProps.run_id,
          'reservation[run_item_id]': info.event.extendedProps.run_item_id,
          'reservation[machine_id]':  info.event.extendedProps.machine_id
        }
      ));
      info.event.remove(); // remove event in case new reservation dialog is cancelled
    }
  }
  return $.extend(fcDefaultConfig(initial_date, undefined), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        Tasks
////////////////////////////////////////////////////////////////////////////////
function fcTasksConfig(calendar_component, initial_date, datesRenderCallback, user_id, readonly) {
  config = {
    eventSources: [
      {
        id: "user-tasks-feed",
        url: Routes.events_user_path(user_id, { readonly: readonly, format: "json", only_tasks: true }),
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "user-tasks-feed"),
      },
      {
        id: "user-reservations-feed",
        url: Routes.events_user_path(user_id, { readonly: readonly, format: "json", only_reservations: true }),
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "user-reservations-feed")
      },
      holidays_feed(calendar_component),
      {
        id: 'user-absence-feed',
        url: Routes.absent_user_path(user_id, { format: "json" }),
        className: 'type-absence',
        defaultAllDay: true,
        editable: false,
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "user-absence-feed")
      },
      {
        id: 'user-schedule-feed',
        url: Routes.user_calendar_schedule_path(user_id, { format: "json" }),
        className: 'type-schedule',
        defaultAllDay: true,
        // overlap: false, // FIXME should work, but does not, we have to set it on the object
        editable: false,
        eventDataTransform: calendarTogglerDataTransform(calendar_component, "user-schedule-feed")
      }
    ],
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
    },
  };

  if (!readonly) {
    $.extend(config, {
      height: "calc(100vh - 200px)",
      editable: true,
      selectable: true,
      snapDuration: '00:15:00',
      eventOverlap: function(event, moving) {
        // BUGFIX: overlap does not work
        if (!event.id) return true;
        // tasks and absences can overlap reservations
        if (event.extendedProps.ibook_type === "reservation") return true
        // same types can not overlap
        if (event.extendedProps.ibook_type === moving.extendedProps.ibook_type) return false
        // task can overlap with absences if allowed
        if (moving.extendedProps.ibook_type === "task" &&
            event.extendedProps.ibook_type === "absence" &&
            event.extendedProps.ibook_overlap) return true
        // absence can overlap with task if allowed
        if (moving.extendedProps.ibook_type === "absence" &&
            event.extendedProps.ibook_type === "task" &&
            moving.extendedProps.ibook_overlap) return true
        return false
      },
      selectOverlap: function(event) {
        // BUGFIX: overlap does not work (sometimes yes)
        if (!event.id) return true;
        return event.overlap || event.extendedProps.ibook_overlap
      },
      eventDrop: function(event) {
        event_change(calendar_component, event.event, 'task', event.revert);
      },
      eventResize: function(event) {
        event_change(calendar_component, event.event, 'task', event.revert);
      },
      select: function(selectInfo) {
        common.openModal(Routes.new_user_task_path(
          user_id,
          {
            'start_at': selectInfo.startStr,
            'stop_at': selectInfo.endStr
          }));
      },
      selectAllow: function(selectInfo) {
        // constrain to single day
        var new_end_date = new Date(selectInfo.end.getTime() - 1*60000); // -1 minute
        return selectInfo.start.getUTCDate() === new_end_date.getUTCDate();
      },
      eventAllow: function(selectInfo, _draggedEvent) {
        // constrain to single day
        var new_end_date = new Date(selectInfo.end.getTime() - 1*60000); // -1 minute
        return selectInfo.start.getUTCDate() === new_end_date.getUTCDate();
      }
    });
  }

  return $.extend(fcDefaultConfig(initial_date, datesRenderCallback), config);
}

function holidays_feed(calendar_component) {
  return {
    id:            'holidays-feed',
    url:           Routes.holidays_path({ format: "json" }),
    className:     'type-holiday',
    defaultAllDay: true,
    editable:      false,
    eventDataTransform: calendarTogglerDataTransform(calendar_component, "holidays-feed"),
  };
};

function calendarTogglerDataTransform(calendarComponent, sourceId) {
  return (eventData) => {
    if (calendarComponent?.calendarToggler) {
      eventData.display = calendarComponent.calendarToggler.showFeeds[sourceId] ? "auto" : "none";
    }
    eventData;
  }
}

module.exports = { refresh_fullcalendar, redraw_fullcalendar, gotoDate, fcClusterReservationConfig, fcMachineReservationConfig, fcRunReservationConfig, fcTasksConfig, fcQueueReservationConfig };

function update_ooo(select_absence, checkbox_id) {
  checkbox = $(checkbox_id);
  if (!checkbox) {
    return;
  }
  current_id = parseInt($(select_absence).val());
  if (!current_id) {
    return;
  }
  ooo_ids = $(select_absence).data("ooo-ids");
  checked =  ooo_ids.includes(current_id);
  checkbox.prop("checked", checked);
}

module.exports = { update_ooo }

import { I18n } from "i18n-js";
import translations_de from "../locales/de.json";
import translations_en from "../locales/en.json";
const li18n = new I18n();

li18n.locale = locale; // set locale from global var
li18n.store(translations_de);
li18n.store(translations_en);

export const i18n = li18n;

export class CalendarToggler {

  constructor(el, fcNode, feedVisibility) {
    this.baseEl = el;
    this.fcNode = fcNode;
    this.showFeeds = feedVisibility; // all other calendars are shown on default
    this.loadFeeds();
    this.addEventListeners();
  }

  loadFeeds() {
    const eventSourceIds = this.fcNode.calendar.getEventSources().map((x) => x.id);
    this.baseEl.innerHTML = "";
    const labels = {
      "user-schedule-feed":        i18n.t("common.calendar_toggler_card.feeds.user_schedule"),
      "holidays-feed":             i18n.t("common.calendar_toggler_card.feeds.holidays"),
      "user-absence-feed":         i18n.t("common.calendar_toggler_card.feeds.user_absence"),
      "user-reservations-feed":    i18n.t("common.calendar_toggler_card.feeds.reservations"),
      "user-tasks-feed":           i18n.t("common.calendar_toggler_card.feeds.user_tasks"),
      "machine-reservations-feed": i18n.t("common.calendar_toggler_card.feeds.reservations"),
      "cluster-reservations-feed": i18n.t("common.calendar_toggler_card.feeds.reservations"),
      "utilization-feed":          i18n.t("common.calendar_toggler_card.feeds.utilization"),
      "runs-overviews-feed":       i18n.t("common.calendar_toggler_card.feeds.runs_overviews"),
    };
    for (let id of eventSourceIds) {
      if (this.showFeeds[id] == undefined) {
        this.showFeeds[id] = true;
      }
      this.baseEl.insertAdjacentHTML("afterbegin",
        '<label class="clickable"><input '+ (this.showFeeds[id] ? "checked" : "") + ' type="checkbox" value="'+ id + '"/> '+ (labels[id] || id) + '</label><br/>');
    }
    this.toggleEvents();
  }

  toggleEvents() {
    let changed = false;
    for (let calEv of this.fcNode.calendar.getEvents()) {
      let actualDisplay = (calEv.display != "none");
      let savedDisplay = this.showFeeds[calEv.source.id];
      if (actualDisplay != savedDisplay) {
        changed = true;
        calEv.setProp("display", savedDisplay ? "auto" : "none");
      }
    }
    if (changed) this.fcNode.refresh();
  }

  addEventListeners() {
    this.baseEl.addEventListener("change", (ev) => {
      this.showFeeds[ev.target.value] = ev.target.checked;
      this.toggleEvents();
    });
  }

}

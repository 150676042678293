function update_internal_roles() {
  primary_role_radio = $("input[name='user[roles][]'][type='radio']:checked");

  if (!primary_role_radio) {
    return;
  }

  internal_active = primary_role_radio.val() == "intern";

  $("input[name='user[roles][]'][type='checkbox']").prop("disabled", !internal_active);
}

module.exports = { update_internal_roles }

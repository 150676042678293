function init_datetimepicker(container) {

  $('.datetimepicker-input.datetimepicker', container).each( function() {
    try {
      // Workaround: clear value because bootstrap4-datetime-picker-rails sometimes fails
      // if value is already set (recursion error) and set it back after initialisation
      var val = $(this).val();
      $(this).val('');
      $(this).data({target: "#" + this.id});
      $(this).datetimepicker({
        locale: moment.locale(),
        stepping: $(this).attr("data-stepping"),
        sideBySide: true,
        allowInputToggle: true,
        format: "ddd DD.MM.YYYY HH:mm",
        widgetParent: ".datetimepicker-container",
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'  // Workaround: Because widget might be cut on top if shown in a modal
        }
      });
      $(this).val(val);
      $(this).on('show.datetimepicker', function() {
	console.log("xxx");
        var datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');
        var top = $(this).offset().top - $(this).outerHeight();
        var left = $(this).offset().left;
        datepicker.css({
          'top': top + 'px',
          'bottom': 'auto',
          'left': left + 'px'
        });
      });
    } catch(err) {
      console.log(err);
    }
  });

  $('.datetimepicker-input.timepicker', container).each( function() {
    try {
      // Workaround: clear value because bootstrap4-datetime-picker-rails sometimes fails
      // if value is already set (recursion error) and set it back after initialisation
      var val = $(this).val();
      $(this).val('');
      $(this).data({target: "#" + this.id});
      $(this).datetimepicker({
        locale: moment.locale(),
        stepping: $(this).attr("data-stepping"),
        sideBySide: true,
        allowInputToggle: true,
        format: "HH:mm",
        widgetParent: ".datetimepicker-container",
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'  // Workaround: Because widget might be cut on top if shown in a modal
        }
      });
      $(this).val(val);
      $(this).on('show.datetimepicker', function() {
        var datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');
        var top = $(this).offset().top - $(this).outerHeight();
        var left = $(this).offset().left;
        datepicker.css({
          'top': top + 'px',
          'bottom': 'auto',
          'left': left + 'px'
        });
      });
    } catch(err) {
      console.log(err);
    }
  });

  $('.datetimepicker-input.datepicker', container).each( function() {
    try {
      var val = $(this).val();
      $(this).val('');
      $(this).data({target: "#" + this.id});
      $(this).datetimepicker({
        locale: moment.locale(),
        allowInputToggle: true,
        format: "ddd DD.MM.YYYY",
        widgetParent: ".datetimepicker-container",
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'  // Workaround: Because widget might be cut on top if shown in a modal
        }
      });
      $(this).val(val);
      $(this).on('show.datetimepicker', function() {
        var datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');
        var top = $(this).offset().top - $(this).outerHeight();
        var left = $(this).offset().left;
        datepicker.css({
          'top': top + 'px',
          'bottom': 'auto',
          'left': left + 'px'
        });
      });
    } catch(err) {
      console.log(err);
    }
  });
}

module.exports = { init_datetimepicker }

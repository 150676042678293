
function do_import() {
  start_loading();
  $("#material-import-form #import_action").val("import");
  Rails.fire($("#material-import-form")[0], 'submit')
}

function preview() {
  start_loading();
  $("#material-import-form #import_action").val("preview");
  Rails.fire($("#material-import-form")[0], 'submit')
}

function start_loading() {
  console.log("start");
  $("#material-import-server-answer .result").empty();
  $("#material-import-server-answer .loading").removeClass("invisible");

  $("#material-import-form").on("ajax:complete", stop_loading);
}

function stop_loading() {
  console.log("stop");
  $("#material-import-server-answer .loading").addClass("invisible");
  $("#material-import-form").off("ajax:complete", stop_loading);
}

module.exports = {
  do_import,
  preview
}

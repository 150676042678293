export default class DrawingCanvas {

  constructor (canvas) {

    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.addEventListenersForMouse();
    this.addEventListenersForTouch();
    this.mousedown = false;
    this.mouseX = null;
    this.mouseY = null;
    this.ongoingTouches = new Map();

  }

  addEventListenersForMouse () {

    document.addEventListener(
      "mouseup",
      (ev) => {
        ev.preventDefault();
        this.mousedown = false;
      }
    );

    this.canvas.addEventListener(
      "mousedown",
      (ev) => {
        ev.preventDefault();
        this.updatePoint(
          ev.offsetX,
          ev.offsetY
        );
        this.mousedown = true;
        this.drawPoint(
          ev.offsetX,
          ev.offsetY
        );
      }
    );

    this.canvas.addEventListener(
      "mousemove",
      (ev) => {
        ev.preventDefault();
        if (this.mousedown) {
          this.drawLine(
            this.mouseX,
            this.mouseY,
            ev.offsetX,
            ev.offsetY
          );
          this.updatePoint(
            ev.offsetX,
            ev.offsetY
          );
        }
      }
    );

    this.canvas.addEventListener(
      "mouseenter",
      (e) => {
        e.preventDefault();
        if (this.mousedown)
          this.updatePoint(e.offsetX, e.offsetY);
      }
    );

  }

  addEventListenersForTouch () {

    this.canvas.addEventListener(
      "touchstart",
      (ev) => {
        ev.preventDefault();
        const touches = ev.changedTouches,
          offsetX = this.canvas.getBoundingClientRect().left,
          offsetY = this.canvas.getBoundingClientRect().top;
        for (let i = 0; i < touches.length; i++) {
          const t = touches[i];
          this.ongoingTouches.set(
            t.identifier,
            t
          );
          this.drawPoint(
            t.clientX - offsetX,
            t.clientY - offsetY
          );
        }
      }
    );

    const touchDelete = (ev) => {
      ev.preventDefault();
      const touches = ev.changedTouches;
      for (let i = 0; i < touches.length; i++) {
        this.ongoingTouches.delete(touches[i].identifier);
      }
    };

    this.canvas.addEventListener(
      "touchend",
      touchDelete
    );
    this.canvas.addEventListener(
      "touchcancel",
      touchDelete
    );

    this.canvas.addEventListener(
      "touchmove",
      (ev) => {
        ev.preventDefault();
        const touches = ev.changedTouches,
          offsetX = this.canvas.getBoundingClientRect().left,
          offsetY = this.canvas.getBoundingClientRect().top;
        this.ctx.save();
        this.ctx.translate(
          offsetX,
          offsetY
        );
        for (let i = 0; i < touches.length; i++) {
          const touch = touches[i],
            prevTouch = this.ongoingTouches.get(touch.identifier);
          if (prevTouch) {
            this.drawLine(
              prevTouch.clientX,
              prevTouch.clientY,
              touch.clientX,
              touch.clientY
            );
            this.ongoingTouches.set(
              touch.identifier,
              touch
            );
          }
        }
        this.ctx.restore();
      }
    );

  }

  drawLine (x0, y0, x1, y1) {
    this.ctx.lineCap = "round";
    this.ctx.beginPath();
    this.ctx.moveTo(x0, y0);
    this.ctx.lineTo(x1, y1);
    this.ctx.stroke();
  }

  drawPoint (x, y) {
    this.ctx.beginPath();
    this.ctx.fillStyle = this.ctx.strokeStyle;
    this.ctx.arc(x, y, this.ctx.lineWidth / 2, 0, Math.PI * 2);
    this.ctx.fill();
  }

  updatePoint (x, y) {
    this.mouseX = x;
    this.mouseY = y;
  }

}

import consumer from "../src/consumer"

consumer.subscriptions.create("PostsChannel", {
  connected: function() {
    console.log("Connected to posts channel.");
  },

  disconnected: function() {
    console.log("Disconnected from posts channel.");
  },

  received: function(data) {
    console.log("Received data in posts channel:");
    console.log(data);
    $("#unread-posts-menu").html(data.unread_posts_dashboard);
    if (!!data.notification) {
      remote_forms.notify("post", data.notification );
    }
    if (!!data.new_post_html) {
      $(".posts-list").each(function() {
        var posts_list = $(this);
        // model-type is undefined within dashboard-card-posts
        if (posts_list.data("model-type") == undefined ||
          (posts_list.data("model-type") == data.new_post_model_type
            && posts_list.data("model-id") == data.new_post_model_id)) {
          var new_post = $(data.new_post_html);
          posts_list.prepend(new_post);
          common.init_js_controls(new_post);
        }
      });
    }
    if (!!data.updated_post_id) {
      var post = $(".post-" + data.updated_post_id)
      $(".read-button, .unread-button", post).tooltip("hide");
      if (data.read) {
        post.addClass("read");
      } else {
        post.removeClass("read");
      }
      // FIXME: click event of all parent elements of ajax buttons is removed in remote_forms; Is this necessary?
      common.init_clickable(post);
    } else if (!!data.deleted_post_id) {
      $(".post-" + data.deleted_post_id).remove();
    }
    $(".posts-list").each(function() {
      var posts_list = $(this);
      var card = posts_list.closest(".card");
      var all_count = this.childElementCount - 1;
      var unread_count = all_count - $(".read", posts_list).length;
      if (unread_count > 0)
	$(".posts-list .no-posts").hide();
      else
	$(".posts-list .no-posts").show();
      $(".all-posts-badge", card).html(all_count);
      $(".unread-posts-badge", card).html(unread_count);
    });
  },
});
